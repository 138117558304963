<template>
    <div ref="append-article">
        <zw-sidebar @shown="shown" :title="$t('multichannel.title.append_article')">
            <div v-if="!loading" class="px-3 py-2">
                <validation-observer tag="div" ref="observer">
                    <b-row>
                        <b-col cols="6">
                            <zw-select-group v-model="form.api_store_id"
                                             name="api_store_id"
                                             label-prefix="multichannel.label."
                                             :options="getStores()"
                                             text-field="name"
                                             value-field="id"
                                             validate="required"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-input-group v-model="form.listing"
                                            name="listing"
                                            label-prefix="multichannel.label."
                                            validate="required"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <zw-table ref="table"
                                      tableName="articles"
                                      columnsPrefix="article.column."
                                      :customSource="getThis()"
                                      :sticky="false"
                                      sticky-header="100%"
                                      :items="myProvider"
                                      :fields="fields"
                                      :filter="filter"
                                      :selected="selected"
                                      :selectedSource="selectedSource"
                            >
                                <template v-slot:top-actions>
                                    <label class="mt-4">{{ $t('multichannel.text.select_articles') }}</label>
                                </template>
                            </zw-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-button block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </div>
        </zw-sidebar>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'AppendArticleModal',
    mixins: [commonSave, commonTable],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                api_store_id: null,
                listing: 'default',
                ids: [],
            },
            form: {},
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticlesTable']),
        ...mapGetters('Multichannel', ['getStores']),
        shown() {
            this.$set(this, 'selected', [])
            this.form = JSON.parse(JSON.stringify(this.defaultForm))
            this.form.api_store_id = this.payload.store_id

            this.loading = false
        },
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Articles/fetchArticles', 'getArticlesTable');
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.form.ids = this.selected.map(row => row.id)
                    this.loading = true

                    this.$store.dispatch('Multichannel/append', {
                        ...{
                            api_store_id: this.form.api_store_id,
                            listing: this.form.listing,
                        },
                        ...this.getSelectedData('articles')
                    })
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>